import React, { useState } from 'react';
import PropTypes from 'prop-types';

import MedicalContentTreeItem from '../_common/MedicalContentTreeItem';
import IntakePlanPane from './IntakePlanPane';
import { useApiClient } from '../../context/ApiClientContext';
import ModuleTreeItem from '../Module/ModuleTreeItem';

const makeTreeItemLabel = (intakePlanReferenceCode, moduleName, translationLocales) => {
  const translationLocaleNames = translationLocales.length > 0 ? ` [${translationLocales.join(', ')}]` : '';
  return `${intakePlanReferenceCode} (${moduleName})${translationLocaleNames}`;
};

export default function IntakePlanTreeItem(props) {
  const {
    intakePlanId,
    intakePlanReferenceCode,
    moduleId,
    moduleName,
    translationLocales,
    setFormPane,
    setTreeManagementServices,
    refreshParent,
  } = props;

  const apiClient = useApiClient();
  const [childNodes, setChildNodes] = useState(null);

  const fetchChildNodes = async () => {
    const result = (await apiClient.getModule(moduleId)).data;
    setChildNodes([<ModuleTreeItem
      key={`${result.uniqueName}_${result.id}`}
      moduleType="base"
      id={result.id}
      actionListId={result.actionListId}
      label={result.uniqueName}
      setFormPane={setFormPane}
      setTreeManagementServices={setTreeManagementServices}
      refreshParent={fetchChildNodes}
    />]);
  };

  const onSelect = () => {
    setFormPane(
      <IntakePlanPane
        intakePlanId={intakePlanId}
        refreshParent={refreshParent}
      />,
    );
    setTreeManagementServices({
      onDeleteWarningMessage: `Delete the IntakePlan "${intakePlanReferenceCode}"?`,
      deleteService: () => apiClient.deleteIntakePlan(intakePlanId),
      onSuccess: refreshParent,
    });
  };

  return (
    <MedicalContentTreeItem
      id={intakePlanId}
      MCTreeItemLabel={makeTreeItemLabel(intakePlanReferenceCode, moduleName, translationLocales)}
      fetchChildNodes={fetchChildNodes}
      MCTreeItemChildNodes={childNodes}
      onSelect={onSelect}
    />
  );
}

IntakePlanTreeItem.propTypes = {
  intakePlanId: PropTypes.number.isRequired,
  intakePlanReferenceCode: PropTypes.string.isRequired,
  moduleId: PropTypes.number.isRequired,
  moduleName: PropTypes.string.isRequired,
  translationLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
  setFormPane: PropTypes.func.isRequired,
  setTreeManagementServices: PropTypes.func.isRequired,
  refreshParent: PropTypes.func.isRequired,
};
